import React, { Fragment } from 'react';
import styles from './heroUni.module.scss';
import Button from '@components/Button';
import CardWithImage from '@components/CardWithImage';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import Img from 'gatsby-image';
import SVG from 'react-inlinesvg';

const HeroUni = ({ title, text, bottomText, downloadButton, heroCards, processRef, heroCardsWrapper, heroBottomTitle, heroBottomDescription, darkBottom }) => {
    return (
        <Fragment>
            <div className={styles.homeHeroWrapper}>
                <div className={styles.backgroundContainer}>
                    <SVG src="/images/home-hero-background.svg" alt="background" />
                    <div className={styles.heading}>
                        <h1>{title}</h1>
                        <p>{text}</p>
                    </div>
                </div>
                <div className={styles.midgroundContainer}>
                    <img src="/images/midground.svg" alt="midgroundConcert" />
                </div>
                <div className={styles.foregroundContainer}>
                    <img src="/images/foreground.svg" alt="foregroundConcert" />
                </div>
            </div>
            {downloadButton && (
                <div className={styles.content}>
                    <h2>Our Mission</h2>
                    <p>{bottomText}</p>
                    <div className={styles.downloadButton}>
                        <Button text="Why LiveYou? - About Us" link="/about" />
                    </div>
                </div>
            )}
            {darkBottom && (
                <div className={styles.content}></div>
            )}
            {heroCardsWrapper && (
            <>
                <Divider margin={4}/>
                <div className={styles.heroCardsWrapper}>
                    <Grid>
                        <Row center={'xs'}>
                            {heroCards?.map(({ title, text, link, linkText, image, scroll }, index) => (
                                <Col lg={4} sm={6} xs={12} key={title}>
                                    {!!scroll ? (
                                        <CardWithImage
                                            image={image?.childImageSharp?.fluid}
                                            title={title}
                                            text={text}
                                            buttonText={linkText}
                                            scroll={scroll}
                                            processRef={processRef}
                                        />
                                    ) : (
                                        <CardWithImage
                                            image={image?.childImageSharp?.fluid}
                                            title={title}
                                            text={text}
                                            buttonText={linkText}
                                            buttonLink={link}
                                        />
                                    )}
                                </Col>
                            ))}
                            <Divider xsMargin={4} smMargin={4}/>
                        </Row>
                    </Grid>
                </div>
                </>
            )}
        </Fragment>
    );
};

export default HeroUni;
