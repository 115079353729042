import React, { Fragment, useRef, useEffect, useState, useLayoutEffect } from 'react';
import styles from './styles.module.scss';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Header from '@components/Header';
import GoingToUniBanner from '@components/GoingToUniBanner';
import HeroUni from '@components/HeroUni/HeroUni';
import ContactCTA from '@components/ContactCTA';
import useWindowDimensions from '@helpers/hooks/useWindowDimensions';

const GoingToUni2 = ({
    data: {
        page: {
            frontmatter: { title, items, path, description, heroGraphic, heroBottomGraphic },
        },
    },
}) => {
    // const [elementsOffset, setElementsOffset] = useState();
    // const {width: windowWidth} = useWindowDimensions();
    // const $imageContainerRef = useRef();
    // const $descriptionRef = useRef();
    // const md = 768;
    // const isMd = windowWidth < md;

    // useLayoutEffect(() => {
    //     if ($imageContainerRef?.current && $descriptionRef?.current && windowWidth) {
    //         const combined = ($descriptionRef.current.clientWidth + $imageContainerRef.current.clientWidth);
    //         const offset = (windowWidth - combined);
    //         setElementsOffset(offset)
    //     }
    // }, [windowWidth]);

    return (
        <Layout>
            <SEO title={`Going to uni`} />
            {/*<GoingToUniBanner />*/}
            <HeroUni
                title={"Going to University"}
                text={"Welcome to some of the most exciting years of your life! LiveYou can help you make the most out of university life as a disabled student. Here’s how…"}
                bottomText={"description"}
                darkBottom
            />
            
            <div className={styles.firstWrapper}>
                <Grid>
                    <Row>
                        <Col xs={12} md={6}>
                                {/* <img src={`/images/${heroBottomGraphic}`} alt={"Uni section image"} className={styles.uniHeroBottomImage} style={{ transform: !isMd ? `translateX(-${elementsOffset / 2}px)` : undefined }} /> */}
                                
                                <img src={`/images/${heroBottomGraphic}`} alt={"Uni section image"} className={styles.uniHeroBottomImage} />
                        </Col>
                        <Col xs={12} md={6}>
                            <div dangerouslySetInnerHTML={{ __html: description }} className={styles.uniHeroBottomDescriptionContainer}/>
                        </Col>
                    </Row>
                </Grid>
            </div>




            <Divider margin={4}/>
            <Divider margin={4}/>
            <div className={styles.uniWrapper}>
                <div className={styles.uniSectionContainer}>
                    <Grid>
                        {items?.map((item, index) => (
                            <Fragment key={index}>
                                {!!index && (
                                    <Fragment>
                                        <Divider margin={4} />
                                        <Divider xsMargin={4} />
                                    </Fragment>
                                )}
                                <Row>
                                    <Col xs={12} md={6} shrink>
                                        <Divider xsMargin={2} smMargin={3} xlMargin={5} />
                                        <div
                                            className={`${styles?.uniSectionTextContainer} ${
                                                index === 0 || index % 2 === 0
                                                    ? styles.rightText
                                                    : styles.leftText
                                            }`}
                                        >
                                            {!!item?.title && <h3>{item?.title}</h3>}
                                            {!!item?.text && (
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: item?.text }}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} first="xs" last={index % 2 !== 0 && 'md'}>
                                        <Divider xsMargin={2} smMargin={3} xlMargin={5} />

                                        {item?.image && (
                                            <div className={styles.uniSectionImageContainer}>
                                                <Img
                                                    fluid={item?.image?.childImageSharp?.fluid}
                                                    alt="dan and jess"
                                                />
                                            </div>
                                        )}

                                        {item?.imagePath && (
                                            <div className={styles.uniSectionImageContainer}>
                                                <img src={`/images/${item?.imagePath}`} alt={"Uni section image"} className={styles.uniSectionImage}/>
                                            </div>
                                        )}

                                    </Col>
                                </Row>
                            </Fragment>
                        ))}
                    </Grid>
                </div>
                <Divider margin={4}/>
            </div>
            <ContactCTA />
        </Layout>
    );
};

export default GoingToUni2;

export const query = graphql`
    query UniPage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: uni_title
                description: uni_description
                heroBottomGraphic: uni_hero_bottom_graphic
                heroGraphic: uni_hero_graphic {
                    publicURL
                }
                items: uni_items {
                    text: uni_items_item_text
                    title: uni_items_item_title
                    imagePath: uni_items_item_image_path
                    image: uni_items_item_image {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                   
                }
            }
        }
    }
`;
